import React, { useState, useEffect } from 'react';
import request from 'request-promise';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import { addDays, subDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';

import SECRETS from './secrets';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 600px;
  border-radius: 5px;
  padding: 50px 0;
`;

const Row = styled.div`
  height: 25px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const A = styled.a``;

const DateDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  input {
    border: 1px solid #333;
    border-radius: 5px;
    height: 30px;
    text-align: center;
  }
`;

const button = {
  marginBottom: '55px',
  backgroundColor: 'green',
  color: 'white',
  textDecoration: 'none',
  padding: '13px',
  borderRadius: '5px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const buttonDisabled = {
  marginBottom: '55px',
  backgroundColor: '#aaa',
  color: 'black',
  textDecoration: 'none',
  padding: '13px',
  borderRadius: '5px',
  fontWeight: 'bold',
};

const uploadStyle = {
  marginBottom: '55px',
  padding: '13px',
  borderColor: 'black',
  borderRadius: '5px',
  textAlign: 'center',
};

const uploadButton = {
  backgroundColor: 'green',
  color: 'white',
  textDecoration: 'none',
  padding: '13px',
  borderRadius: '5px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const separator = {
  width: '100%',
  height: '32px',
  backgroundColor: '#ccc',
  marginBottom: '55px',
};

const getUnidades = (token, setUnidades) => {
  request({
    method: 'GET',
    uri: `${SECRETS.serverUrl}/unidades`,
    json: true,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then((resp) => {
      setUnidades(resp);
    })
    .catch(err => console.error(err));
};


const MainScreen = ({ token }) => {
  const [ unidades, setUnidades ] = useState([]);
  const [ date, setDate ] = useState(subDays(new Date(), 1));
  const [ loading, setLoading ] = useState(false);
  const [file, setFile ] = useState({});

  useEffect(() => {
    getUnidades(token, setUnidades);
  }, [token,]);

  useEffect(() => {
    registerLocale('es', es);
  }, []);

  const descargar = async() => {
    const URL = `${SECRETS.serverUrl}/reportes/naranja3/all/${format(date, 'yyyy-MM-dd')}/${token}/rutas-${format(date, 'yyyy-MM-dd')}.xlsx`;
    setLoading(true);
    const response = await fetch(URL, { method: 'get', headers: { 'Content-Type': 'application/json' }})
      .catch(() => false);
    if (response) {
      const blob = await response.blob();
      const downloadurl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadurl;
      link.setAttribute('download', `rutas-${format(date, 'yyyy-MM-dd')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    setLoading(false);
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    const buffer = await file.arrayBuffer();
    const workbook = XLSX.read(buffer);
    const worksheets = {};
    workbook.SheetNames.forEach((unidad) => {
      const json = XLSX.utils.sheet_to_json(workbook.Sheets[unidad], { header: 1 });
      let bases = {};
      json.forEach((row, index) => {
        if (/-+/.test(row[0]) && index > 1) {
          const base = json[index - 1][4];
          const retraso = json[index - 1][2];
          if (/[0-9]+/.test(retraso)) {
            if (typeof bases[base] !== 'undefined') {
              bases[base] = { ...bases[base], retraso: bases[base].retraso + parseInt(retraso, 10) };
            } else {
              bases[base] = { base, unidad, retraso: parseInt(retraso, 10) };
            }
          }
        }
      });
      Object.keys(bases).forEach((base) => {
        if (bases[base].base) {
          if (typeof worksheets[bases[base].base] !== 'undefined') {
            worksheets[bases[base].base].push(bases[base]);
            return;
          }
          worksheets[bases[base].base] = [bases[base]];
        }
      });
    });

    Object.keys(worksheets).forEach((base) => {
      worksheets[base] = worksheets[base].sort((a, b) => a.retraso < b.retraso);
    });
    let archivo = XLSX.utils.book_new();

    Object.keys(worksheets).forEach((base) => {
      const output = [
        ['Base', 'Unidad', 'Retraso'],
      ];
      worksheets[base].forEach(row => output.push([row.base, row.unidad, row.retraso]));
      var ws = XLSX.utils.aoa_to_sheet(output);
      XLSX.utils.book_append_sheet(archivo, ws, base);
    })
    const u8 = await XLSX.write(archivo, { type: "array", bookType: "xlsx" });
    const downloadurl = window.URL.createObjectURL(new Blob([u8]));
    const link = document.createElement('a');
    link.href = downloadurl;
    link.setAttribute('download', `bases-${format(date, 'yyyy-MM-dd')}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Container>
      {!token && <Redirect to="/" />}
      {/* <a href="#/checadas">Ir a Reporte de Checadas</a> */}
      <div style={uploadStyle}>
        <h3>Procesar Bases</h3>
        <form onSubmit={uploadFile}>
          <input type="file" onChange={handleFile} placeholder="Archivo a procesar xlsx" />
          <button type="submit" style={uploadButton}>Procesar</button>
        </form>
      </div>
      <div style={separator}></div>
      <h3>Consultar Checadas</h3>
      <DateDiv>
        <span>Selecciona la fecha a consultar</span>
        <DatePicker
          selected={date}
          onChange={setDate}
          minDate={new Date('Oct 25, 2019')}
          maxDate={subDays(new Date(), 1)}
          placeholderText="Selecciona la fecha a consultar"
          locale="es"
        />
      </DateDiv>
      { !loading && (
        <div
          style={button}
          onClick={descargar}
        >
          Descargar todas las rutas del día
        </div>
      )}
      { loading && (
        <div
          style={buttonDisabled}
        >
          Generando Reporte...
        </div>
      )}
      <div>O descargar rutas individualmente:</div>
    {unidades.map(u => (
      <Row key={u.idUnidad}>
        <A
          href={`${SECRETS.serverUrl}/reportes/naranja3/${u.idUnidad}/${format(date, 'yyyy-MM-dd')}/${token}/${u.unidad}/${u.unidad}-${format(date, 'yyyy-MM-dd')}.xlsx`}
          download={`${u.unidad}-${format(date, 'yyyy-MM-dd')}.xlsx`}
          title={`${u.unidad}-${format(date, 'yyyy-MM-dd')}.xlsx`}
          target="_blank"
        >
          {`${u.unidad}-${format(date, 'yyyy-MM-dd')}.xlsx`}
        </A>
      </Row>
    ))}
    </Container>
  );
};

export default MainScreen;
